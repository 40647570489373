import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { addFormData, fetchAllDashboardDataAPI } from '../../utils/api';

const TopSellingProducts = () => {
    const centerid = localStorage.getItem('centerdata');
    const [topselling, setTopSelling] = useState([])

    useEffect(() => {
        getTopAgent(centerid)
    }, [centerid])

    const getTopAgent = (centerid) => {
        fetchAllDashboardDataAPI('center-get-top-selling-product-for-dashboard-data', centerid).then((resp) => {
            if (resp.data.status === 1) {
                setTopSelling(...[resp.data.topproduct])
            }
            else {
                setTopSelling();
            }
        })
    }
    const handleInputs = e => {
        const data = { valuetype: e.target.value, centerid: centerid }
        addFormData('center-get-top-selling-product-date-for-dashboard-data', data).then((resp) => {
            if (resp.data.status === 1) {
                setTopSelling(...[resp.data.topproduct])
            }
            else {
                setTopSelling();
            }
        })
    };

    return (
        <div className="topagentproduct card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="headingtitle">
                        <h6>Top Selling Products</h6>
                    </div>
                    <div className="select">
                        <Form.Select
                            placeholder="Member Type"
                            name="product_date"
                            className="topagentselect"
                            onChange={handleInputs}
                        >
                            <option value="Today">Today</option>
                            <option value="Yesterday">Yesterday</option>
                            <option value="Week">Week</option>
                            <option value="Month">Month</option>
                        </Form.Select>
                    </div>
                </div>
            </div>
            <div className="topagentproductsection">
                {topselling ? topselling?.map((topselling, index) => (
                    <div className="agentbody" key={index}>
                        <span className="name">{topselling?.product_name}</span>
                        <span className="name">{topselling?.total_count}</span>
                    </div>
                ))
                    :
                    <div className="agentbody">
                        <span className="name">Data not founds</span>
                        <span className="name">0</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default TopSellingProducts